<template>
  <div ref="select-mt" class="MTSelect">
    <div
      :class="`MTSelect-select ${showOption ? 'focused' : ''}`"
      :style="`min-width: ${minWidth}px`"
      @click="showOption = !showOption"
    >
      <span :class="`${selectedOptionValue === '' ? 'MTSelect-select__placeholder' : ''}`">{{
        selectedText
      }}</span>
      <div v-if="selectedOptionValue === ''" class="MTSelect-select__chevron">
        <i class="fa fa-chevron-down" aria-hidden="true" />
      </div>
      <i
        v-else
        class="fa fa-times MTSelect-select__delete"
        aria-hidden="true"
        @click="onDeleteOption"
      />
    </div>
    <div v-if="showOption" class="MTSelect-options">
      <div
        v-for="option in options"
        :key="option.value"
        class="options"
        @click="onSelectOption(option)"
      >
        {{ option.text }}
      </div>
      <div v-if="options.length === 0" class="options-empty"></div>
    </div>
  </div>
</template>
<script>
/*
 *  MT Single Select Component
 *
 * */
export default {
  name: 'MTSelect',
  props: {
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
      required: true,
    },
    minWidth: {
      type: Number,
      default: 150,
    },
    value: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      selectedOptionValue: this.value || '',
      selectedOptionText: '',
      showOption: false,
    }
  },
  computed: {
    selectedText() {
      if (this.selectedOptionValue === '') {
        return this.placeholder
      }
      return this.selectedOptionText
    },
  },
  watch: {
    showOption(val) {
      if (!val) {
        document.removeEventListener('mousedown', this.handleClickOutside)
      } else {
        document.addEventListener('mousedown', this.handleClickOutside)
      }
    },
  },
  mounted() {
    if (this.selectedOptionValue !== '') {
      this.selectedOptionText = this.options.find(
        (item) => String(item.value) === String(this.selectedOptionValue)
      ).text
    }
  },
  methods: {
    onSelectOption(option) {
      this.showOption = false
      this.selectedOptionValue = option.value
      this.selectedOptionText = option.text
      if (this.selectedOptionValue !== this.value) {
        this.$emit('update:value', this.selectedOptionValue)
        this.onChange(this.selectedOptionValue)
      }
    },
    handleClickOutside(event) {
      if (this.$refs['select-mt'] && !this.$refs['select-mt'].contains(event.target)) {
        this.showOption = false
      }
    },
    onDeleteOption(e) {
      e.stopPropagation()
      this.selectedOptionText = ''
      this.selectedOptionValue = ''
      this.showOption = false
      if (this.selectedOptionValue !== this.value) {
        this.$emit('update:value', this.selectedOptionValue)
        this.onChange(this.selectedOptionValue)
      }
    },
  },
}
</script>
<style scoped lang="scss">
$successColor: #34c38f;
$errorColor: #f46a6a;
.MTSelect {
  position: relative;
  font-size: 12px;
  z-index: 1;
  .focused {
    box-shadow: 0 0 0 0.03rem $successColor !important;
    border-color: $successColor !important;
  }
  &-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 37px;
    max-width: 300px;
    padding: 0.47rem 0.75rem 0.47rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 10px;
    color: #495057;
    background-color: #fff;
    span {
      padding-right: 10px;
    }
    &__delete {
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
    &__chevron {
      position: absolute;
      right: 0.75rem;
    }
    &__placeholder {
      color: #adb5bd;
    }
  }
  &-options {
    &::-webkit-scrollbar {
      display: none;
    }
    position: absolute;
    top: 35px;
    width: 100%;
    text-indent: 0.75rem;
    background: white;
    border: 1px solid #ced4da;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    max-height: 215px;
    overflow-x: hidden;
    overflow-y: auto;
    .options {
      padding-top: 8px;
      padding-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        cursor: pointer;
        background: #248bf5;
        color: #fff;
      }
      &-empty {
        width: 100%;
        height: 35px;
      }
    }
  }
}
</style>
