<template>
  <Layout>
    <page-layout
      page-titles="利用中のサービス"
      :table-layout="false"
      :top-layout="true"
      :bread-crumb-items="breadCrumbItems"
    >
      <template #top-content>
        <div class="SaasPage">
          <div class="SaasPage__search">
            <search-input :value.sync="searchInput" />
            <common-select
              :value.sync="selectedCategory"
              :options="categoryOptions"
              placeholder="カテゴリー"
              :on-change="reloadData"
              :min-width="180"
            />
          </div>
          <div class="SaasPage__service">
            <saas-service-component
              v-for="service in services"
              :id="service.id"
              :key="service.id"
              :src="service.logo || defaultCompanyLogo"
              :checked="service.is_active"
              :is-maintain="service.isMaintain"
            ></saas-service-component>
          </div></div
      ></template>
    </page-layout>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageLayout from '@/components/commons/common-page-layout'
import SaasServiceComponent from '@/components/saas/saas-service'
import { saasManagementService } from '@/services/saas-management-service'
import { DEFAULT_COMPANY_LOGO, SERVICE_STATUS } from '@/utils/constants'
import SearchInput from '@/components/commons/common-search-input'
import { mapGetters } from 'vuex'
import CommonSelect from '@/components/commons/common-select'

export default {
  name: 'SaaSManagement',
  components: {
    SearchInput,
    Layout,
    PageLayout,
    SaasServiceComponent,
    CommonSelect,
  },
  data() {
    return {
      searchInput: '',
      breadCrumbItems: [
        { text: 'SaaS管理', href: '#' },
        { text: '利用中のサービス', href: '#' },
      ],
      services: [],
      mounted: false,
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      selectedCategory: '',
      categoryOptions: [],
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps_category']),
  },
  watch: {
    searchInput(newValue) {
      this.handleValueChange(newValue)
    },
    perPage() {
      this.handleValueChange(this.searchInput)
    },
    currentPage() {
      this.handleValueChange(this.searchInput)
    },
  },
  async created() {
    const { items } = await saasManagementService.getSaaSList()
    this.services = items
    this.convertValue()
  },
  mounted() {
    this.categoryOptions = this.apps_category.map((item) => ({ value: item.id, text: item.name }))
  },
  methods: {
    async reloadData() {
      const { items } = await saasManagementService.getSaaSList(
        this.searchInput,
        this.selectedCategory
      )
      this.services = items
      this.convertValue()
    },
    async handleValueChange(keyword) {
      await this.reloadData()
    },
    convertValue() {
      this.services.forEach((item) => {
        item.isMaintain = item.status === SERVICE_STATUS.MAINTAIN
      })
    },
  },
}
</script>
