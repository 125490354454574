<template>
  <div
    class="SaasService"
    :data-unchecked="!checked"
    :data-maintain="isMaintain"
    @click="handleClickLogo(id)"
  >
    <div class="SaasService__image">
      <img :src="src" alt="image-service" />
    </div>
    <img
      v-if="checked"
      src="@/assets/icons/ic_saas_checked.svg"
      alt="saas-service image"
      class="SaasService__status"
    />
    <span v-if="isMaintain" class="SaasService__maintain">メンテナンス中</span>
  </div>
</template>
<script>
export default {
  name: 'SaasService',
  props: {
    id: {
      type: Number,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    isMaintain: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    handleClickLogo(id) {
      this.$router.push({
        path: '/customer/saas-management',
        name: 'saas-connection',
        params: {
          id,
        },
      })
    },
  },
}
</script>
